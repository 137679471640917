import axios from 'axios'
import AsyncStorage from '@react-native-community/async-storage'
import 'react-native-get-random-values'
import { errorLog } from 'lib/log'

console.log(process.env.REACT_APP_API_BASE_URL)

const GOOGLE_CLIENT_ID = process.env.REACT_APP_API_GOOGLE_CLIENT_ID
const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const csrfTokenKey = 'ozoapi_csrf_token'

let accessToken = undefined

const LOCAL_STORAGE_TOKEN_KEY = 'ozoapi_tokens'
const loadRefreshToken = async () => {
  try {
    // saving / loading in a legacy format to avoid user disruption
    return JSON.parse(await AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_KEY))
      .refresh
  } catch {
    return undefined
  }
}
const saveRefreshToken = async token => {
  if (token) {
    // saving / loading in a legacy format to avoid user disruption
    await AsyncStorage.setItem(
      LOCAL_STORAGE_TOKEN_KEY,
      JSON.stringify({
        refresh: token,
      })
    )
  } else {
    await AsyncStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  }
}
const hasRefreshToken = async () => {
  try {
    const keys = await AsyncStorage.getAllKeys()
    return keys.includes(LOCAL_STORAGE_TOKEN_KEY)
  } catch {
    return false
  }  
}

let signOutHandlers = []
let verificationStatusHandlers = []

export class ApiError extends Error {
  constructor(message = 'Unknown error', status = 500, data = undefined) {
    super(message)
    this.status = status
    this.apiMessage = message
    this.data = data
  }
}

// axios instance used with API calls
const apiAxios = axios.create({ baseURL: `${API_BASE_URL}/` })
apiAxios.interceptors.response.use(
  response => {
    return response.data.meta ? response.data : response.data.data
  },
  err => {
    if (err.response) {
      throw new ApiError(
        err.response.data.message,
        err.response.status,
        err.response.data.error
      )
    } else if (err.status) {
      throw new ApiError(err.message, err.status)
    } else {
      if (
        err
          .toString()
          .toLowerCase()
          .includes('network error')
      ) {
        throw new ApiError(
          `There seems to be a network error. Please check your connection and try again!`,
          502
        )
      } else {
        throw new ApiError(`Something has gone wrong. Please try again!`, 500)
      }
    }
  }
)

const onSignOut = causedExternally => {
  for (const handler of signOutHandlers) {
    handler(causedExternally)
  }
}

const refreshAccessToken = async isRefreshingStates => {
  accessToken = undefined
  const refreshToken = await loadRefreshToken()
  if (refreshToken) {
    try {
      const tokenRes = await apiAxios.post('auth/token', { refreshToken })
      accessToken = tokenRes.access
      if (!accessToken) {
        throw new Error('no access token after refresh')
      }
      // Notify verification status update
      try {
        for (const handler of verificationStatusHandlers) {
          handler(!!tokenRes.providerIsVerified, tokenRes.roles)
        }
      }
      catch {
        console.error('verificationStatusHandlers threw exception')
      }
    } catch (err) {
      errorLog(err)
      if (err.status === 403) {
        await saveRefreshToken(undefined)
        onSignOut(true)
        throw new ApiError(`It looks like you might have logged out!`, 403)
      } else {
        throw err
      }
    }
  } else {
    if (isRefreshingStates) {
      onSignOut(false)
    }
    throw new ApiError(`It looks like you need to log in!`, 403)
  }
}

const authRequest = async (requestFunc, googleAuthState) => {
  let googleAuthScopes = undefined
  let forceConsent = false
  if (accessToken) {
    try {
      return await requestFunc(accessToken)
    } catch (err) {
      if (err.status !== 401) {
        throw err
      }
      // this failure may be do needing 3rd party (Goggle) scope permissions
      if (err.data && err.data.googleScopes) {
        // if the user has indicated that they would like permissions escalation, set the scopes that we need
        if (googleAuthState) {
          googleAuthScopes = err.data && err.data.googleScopes
          forceConsent = err.data && err.data.googleBadToken
        } else {
          throw err
        }
      }
    }
  }
  if (googleAuthScopes) {
    // TODO: the non-web version of Google auth would just be able to continue on to the second request attempt...
    return googleAuth({
      scopes: googleAuthScopes,
      state: googleAuthState,
      sub: googleAuthState.sub,
      forceConsent,
    })
  } else {
    await refreshAccessToken()
  }
  return requestFunc(accessToken).catch(err => {
    if (err.status === 401) {
      accessToken = undefined
    }
    throw err
  })
}

// TODO: this could possible also be replaced by some sort of axios injection
const apiHeaders = (token, contentType) => {
  const headers = {
    'Content-Type': contentType || 'application/json',
  }
  // NOTE: we can't really inline this with a token ? token : undefined pattern, because
  // axios will actually put 'undefined' in the Authorization field, messing some things up
  // in the API where "optional" authentication is used.
  if (!!token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return {
    headers,
  }
}

const post = async (url, data, contentType = undefined) =>
  apiAxios.post(url, data, apiHeaders(undefined, contentType))
const patch = async (url, data) => apiAxios.patch(url, data, apiHeaders())
const get = async url => apiAxios.get(url, apiHeaders())

const authPost = async (
  url,
  data,
  contentType = undefined,
  googleAuthState = undefined
) =>
  authRequest(
    token => apiAxios.post(url, data, apiHeaders(token, contentType)),
    googleAuthState
  )
const authPut = async (url, data, googleAuthState = undefined) =>
  authRequest(
    token => apiAxios.put(url, data, apiHeaders(token)),
    googleAuthState
  )
const authPatch = async (url, data, googleAuthState = undefined) =>
  authRequest(
    token => apiAxios.patch(url, data, apiHeaders(token)),
    googleAuthState
  )
const authGet = async (url, googleAuthState = undefined) =>
  authRequest(token => apiAxios.get(url, apiHeaders(token)), googleAuthState)
const authDelete = async (url, googleAuthState = undefined) =>
  authRequest(token => apiAxios.delete(url, apiHeaders(token)), googleAuthState)

async function signIn({ provider, credential }) {
  const result = await post('auth/signin', {
    provider,
    credential,
    roles: ['ozobot.com/classroom/educator'],
  })
  accessToken = result.access
  await saveRefreshToken(result.refresh)

  result.access = undefined
  result.refresh = undefined
  return result
}

async function link({ provider, credential }) {
  return await authPost('auth/link', {
    provider,
    credential,
  })
}

function generateCSRFToken() {
  return btoa(crypto.getRandomValues(new Uint8Array(32)))
}

function encodeGoogleOauthState(
  csrfToken,
  state,
  linkToCurrentAccount = false
) {
  return `googleauth:${csrfToken}:${btoa(
    encodeURIComponent(JSON.stringify(state || null))
  )}:${!!linkToCurrentAccount}`
}

function parseGoogleOauthState(compositeState) {
  if (compositeState) {
    const parts = compositeState.split(':')
    if (parts[0] === 'googleauth') {
      return {
        csrfToken: parts[1],
        state: JSON.parse(decodeURIComponent(atob(parts[2]))),
        linkToCurrentAccount: parts[3] === 'true',
      }
    }
  }
}

function isAppLoadedInIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

async function googleAuth({
  scopes = [],
  linkToCurrentAccount = false,
  state = undefined,
  sub = undefined,
  forceConsent = false,
}) {
  const csrfToken = generateCSRFToken()
  await AsyncStorage.setItem(csrfTokenKey, csrfToken)

  const scopesSet = new Set([
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
    ...scopes,
  ])

  // TODO: remove approval_prompt=force in final...
  const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${encodeURI(
    GOOGLE_CLIENT_ID
  )}&scope=${encodeURI(
    [...scopesSet].join(' ')
  )}&redirect_uri=${encodeURIComponent(
    GOOGLE_REDIRECT_URI
  )}&response_type=code&state=${encodeURI(
    encodeGoogleOauthState(csrfToken, state, linkToCurrentAccount)
  )}${forceConsent ? '&prompt=consent' : ''}${
    sub ? `&login_hint=${sub}` : ''
  }${'&access_type=offline&include_granted_scopes=true'}`

  if (isAppLoadedInIframe()) {
    window.open(url, '_blank');
  } else {
    window.location.assign(url)
  }

  // just wait indefinitely for the url change to take effect
  return new Promise(() => {})
}

// check this on the GOOGLE_REDIRECT_URI to determine if we should continue with the google sign-in process
async function parseGoogleSignInRedirectedUrl(redirectedUrl) {
  const url = new URL(redirectedUrl)
  const stateInfo = parseGoogleOauthState(url.searchParams.get('state'))
  if (stateInfo) {
    const storedCSRFToken = await AsyncStorage.getItem(csrfTokenKey)
    await AsyncStorage.removeItem(csrfTokenKey)
    if (!storedCSRFToken || stateInfo.csrfToken !== storedCSRFToken) {
      // FIXME: log with sentry, so we're aware of possible attack
      // throw new Error('Google auth CSRF mismatch exception')
      return
    }
    return {
      authCode: url.searchParams.get('code'),
      state: stateInfo.state,
      parseGoogleOauthState: stateInfo.parseGoogleOauthState,
      linkToCurrentAccount: stateInfo.linkToCurrentAccount,
    }
  }
}

async function isSignedIn() {
  const res = await loadRefreshToken()
  return !!res
}

async function getStorageLink({ fileName, folder, namespace, mime }) {
  fileName = fileName.trim()
    .replace(/[^a-zA-Z0-9.]+/g, '-')
    .replace(/-\./g, '.')
    .replace(/\.-/g, '.')
    .replace(/\.\./g, '.')

  // upload file to storage
  return authPost(
    `storage/${namespace}/${folder}/${fileName}`,
    { mimeType: mime }
  )
}
class ClientApi {
  // called when user has signed out -- the handler
  // is passed [true] if the sign out was externally caused
  // (revoked token), etc...
  // Returns a function to remove handler
  subscribeUserSignedOut(handler) {
    signOutHandlers.push(handler)
    return () => {
      signOutHandlers = signOutHandlers.filter(h => h !== handler)
    }
  }

  // Called when we get verification status information (usually on refreshing access token)
  subscribeVerificationStatus(handler) {
    verificationStatusHandlers.push(handler)
    return () => {
      verificationStatusHandlers = verificationStatusHandlers.filter(h => h !== handler)
    }
  }

  /** Start Auth **/

  async userAuthEmailProviderExists({ email }) {
    return post(`auth/providers/check`, {
      providerId: email,
      providerType: 'email',
    })
  }

  async userAuthSignIn({ provider, credential }) {
    return signIn({
      provider,
      credential,
    })
  }

  async userAuthGoogleSignIn({
    isStudent,
    state,
    linkToCurrentAccount = false,
  }) {
    // TODO: native version
    return googleAuth({
      scopes: isStudent
        ? ['https://www.googleapis.com/auth/classroom.coursework.me']
        : [],
      state,
      linkToCurrentAccount,
      forceConsent: true,
    })
  }

  // call this from the google redirect page
  // onStateParsed can be used to grab the original state passed into the
  // google auth call, before an exception is thrown by internal calls to the API
  async userAuthFinishWebGoogleSignIn({ url, onStateParsed = undefined }) {
    const info = await parseGoogleSignInRedirectedUrl(url)
    if (!info) {
      throw new Error('Not a redirect url')
    }
    onStateParsed && onStateParsed(info.state, info.linkToCurrentAccount)
    const credential = {
      authCode: info.authCode,
      clientId: GOOGLE_CLIENT_ID,
      redirectUri: GOOGLE_REDIRECT_URI,
    }
    if (info.linkToCurrentAccount) {
      return {
        state: info.state,
        providers:
          info.authCode &&
          (await link({
            provider: 'google',
            credential,
          })),
      }
    } else {
      return {
        state: info.state,
        user:
          info.authCode &&
          (await signIn({
            provider: 'google',
            credential,
          })),
      }
    }
  }

  async userAuthSignOut() {
    const refreshToken = await loadRefreshToken()
    if (refreshToken) {
      await post('auth/signout', { refreshToken })
      accessToken = undefined
      await saveRefreshToken(undefined)
    }

    onSignOut(false)
  }

  // this can be used to check if the user has signed out this local instance from another instance
  // if "checkLocalOnly" is true, we'll just check locally, and sign out if need be. If not true, will
  // do an API call to verify deep sign-in status
  async verifyUserWasNotSignedOut(checkLocalOnly) {
    if (checkLocalOnly) {
      if (!(await isSignedIn())) {
        onSignOut(false)
        throw new Error('Was signed out')
      }
    } else {
      await refreshAccessToken(true)
    }
  }
  async verifyUserWasNotSignedIn() {
    if (await isSignedIn()) {
      throw new Error('Was signed out')
    }
  }

  async userSendResetPassword({ email }) {
    return post('auth/send_reset_password', { email })
  }

  async userSendVerifyEmail({ email }) {
    return post('auth/send_verify_email', { email })
  }

  async finishPasswordReset({ password, token }) {
    await post(`auth/reset_password?token=${token}`, { password })
  }

  async finishEmailVerification({ token }) {
    await post(`auth/verify_email?token=${token}`, {})
  }

  async userPasswordChange({ email, oldPassword, newPassword }) {
    await post('auth/change_password', {
      email,
      password: oldPassword,
      newPassword,
    })

    // the refresh token will have reset, so sign in again
    await signIn({
      provider: 'email',
      credential: {
        email,
        password: newPassword,
      },
    })
  }

  async userLinkProvider({ provider, credential }) {
    return await authPost('auth/link', { provider, credential })
  }

  async userUnlinkProvider({ provider, providerId }) {
    return await authPost('auth/unlink', { provider, providerId })
  }

  /** Start Classes **/

  async getClasses() {
    return await authGet(`classes`)
  }

  async createClass({ payload }) {
    return await authPost(`classes`, payload)
  }

  async createProviderClass(payload) {
    return await authPost(`classes/providers`, payload)
  }

  async getClass({ id }) {
    return await authGet(`classes/${id}`)
  }

  async updateClass({ payload }) {
    return await authPatch(`classes/${payload.id}`, payload)
  }

  async deleteClass({ id }) {
    return await authDelete(`classes/${id}`)
  }

  async classesStudentCreate({ id, ...payload }) {
    return await authPost(`classes/${id}/students`, payload)
  }

  async classesStudentUpload({ id, fd }) {
    return await authPost(`classes/${id}/students`, fd, 'multipart/form-data')
  }

  async syncClass({ payload, state }) {
    return await authPost(
      `classes/${payload.id}/sync`,
      payload,
      undefined,
      state
    )
  }

  async createGoogleAssignment({ payload, state }) {
    const data = payload
    return await authPost(
      `classes/${data.classId}/assignments/`,
      data,
      undefined,
      state
    )
  }

  async submitGoogleStudentAssignment({ payload, state }) {
    const { assignmentId } = payload
    return await authPost(
      `assignments/${assignmentId}`,
      payload,
      undefined,
      state
    )
  }

  async getGoogleAssignment({ payload, state }) {
    const { assignmentId } = payload
    return await authGet(`assignments/${assignmentId}`)
  }

  async deleteGoogleAssignment({ payload, state }) {
    const { id } = payload
    return await authDelete(`assignments/${id}`, state)
  }

  async listGoogleAssignments({ payload, state }) {
    const { classId } = payload
    return await authGet(`assignments?classId=${classId}`)
  }

  async getStudentAssignment({ payload, state }) {
    const { studentAssignmentId } = payload
    return await authGet(
      `studentAssignments/${studentAssignmentId}`,
      payload,
      undefined,
      state
    )
  }

  async listStudentAssignments({ payload, state }) {
    const { assignmentId } = payload
    return await authGet(`studentAssignments?assignmentId=${assignmentId}`)
  }

  async updateGoogleStudentAssignment({ payload, state }) {
    const { id } = payload
    return await authPut(`studentAssignments/${id}`, payload, state)
  }

  async submitGoogleAssignment({ payload, state }) {
    const { classId, providerSubmissionId } = payload
    return await authPost(
      `classes/${classId}/assignments/${providerSubmissionId}`,
      payload,
      undefined,
      state
    )
  }

  /** Start Random **/

  // async userEventCreate(payload) {
  //   return await post('events', payload)
  // }

  async sendAnalyticEvent({ type, data, common, timestamp }) {
    await patch('analytic/events', {
      events: [
        {
          type,
          occurredAt: timestamp || new Date().toISOString(),
          data,
        },
      ],
      common,
    })
  }

  async sendBotConnectionLogs({ logs }) {
    await patch('analytic/bot-connection-logs', { logs })
  }

  async uploadDataToStorage({
    namespace,
    folder,
    fileName,
    data,
    mime = undefined,
  }) {
    const fileInfo = await getStorageLink({
      namespace,
      folder,
      fileName,
      mime,
    })
    await axios.put(fileInfo.uploadURL, data, {
      headers: { 'Content-Type': mime || 'application/octet-stream' },
    })
    // this change is to fix typescript issues with Axios<any> not castable to string
    return '' + fileInfo.url
  }

  // File is a file object returned via picker
  async uploadBlobToStorage({
    namespace,
    folder,
    fileName,
    blob,
    mime = undefined
  }) {
    const fileInfo = await getStorageLink({
      namespace,
      folder,
      fileName,
      mime,
    })
    await axios.put(fileInfo.uploadURL, blob, {
      headers: { 'Content-Type': mime || 'application/octet-stream' },
    })
    // this change is to fix typescript issues with Axios<any> not castable to string
    return '' + fileInfo.url
  }

  async getAssets() {
    return await get(`assets?tag=occ2`)
  }

  async autocompleteService(params) {
    let args = ''
    for (let key in params) {
      args += '&' + key + '=' + params[key].replace(' ', '%20')
    }
    return await get(`autocomplete?${args.substring(1)}`)
  }

  /** Start Lessons **/

  // async searchLessons({
  //   author,
  //   Subject,
  //   Robot,
  //   standards,
  //   Grade,
  //   Coding,
  //   durationMin,
  //   durationMax,
  //   publishedMin,
  //   publishedMax,
  //   unpublished,
  //   all,
  //   query,
  //   page,
  //   limit,
  // }) {
  //   const list = v => (v ? v.join(',') : undefined)

  //   const params = {
  //     author: author,
  //     subjects: list(Subject),
  //     bots: list(Robot),
  //     standards: list(standards),
  //     grades: list(Grade),
  //     coding: list(Coding),
  //     mindur: durationMin ? durationMin.toString() : undefined,
  //     maxdur: durationMax ? durationMax.toString() : undefined,
  //     pubmin: publishedMin ? publishedMin.toISOString() : undefined,
  //     pubmax: publishedMax ? publishedMax.toISOString() : undefined,
  //     unpublished:
  //       unpublished !== undefined ? unpublished.toString() : undefined,
  //     all: all !== undefined ? all.toString() : undefined,
  //     page: page,
  //     limit: limit,
  //     query,
  //   }

  //   const paramStrs = Object.keys(params)
  //     .filter(param => params[param])
  //     .map(param => `${param}=${encodeURIComponent(params[param])}`)
  //   const q = paramStrs.length > 0 ? `?${paramStrs.join('&')}` : ''

  //   // API uses any values in auth to determine if admin, user, or not signed in
  //   // This determines what kinds of lessons are sent.
  //   return await get(`lessons${q}`)
  // }

  async searchLessons({ key }) {
    return await authGet(`lessons/search?${key}`)
  }

  async getLessons({ key }) {
    return await authGet(`lessons`)
  }

  async getLesson({ id }) {
    const url = `lessons/${id}`
    const signedIn = await hasRefreshToken()
    return signedIn
      ? await authGet(url)
      : await get(url)
  }

  // Keep: used in new
  async getOwnedLessons({ accountId }) {
    // This is for getting all published lessons and a users' own lessons from the API
    return await authGet(`lessons?authorId=${accountId}`)
  }

  async makeLessonAttachment(data) {
    return await post(`lessons/make-attachment`, data)
  }

  async submitLesson({ lesson }) {
    return await authPost(`lessons`, lesson)
  }

  async resubmitLesson({ lesson, id }) {
    return await authPut(`lessons/${id}`, lesson)
  }

  async deleteDraft({ id }) {
    return await authDelete(`lessons/${id}`)
  }

  async publishLesson({ id }) {
    return await authPatch(`lessons/${id}`, { status: 'published' })
  }

  /**
   * Begin new API api names for lesson creation
   * Remove submitLesson, resubmitLesson when done
   * */

  async createLesson({ payload }) {
    return await authPost(`lessons`, payload)
  }

  async updateLesson({ payload }) {
    return await authPut(`lessons/${payload.id}`, payload)
  }

  async deleteLesson({ id }) {
    return await authDelete(`lessons/${id}`)
  }

  async rateLesson({ lessonId, rating, applyForUser = false }) {
    const url = `/lessons/${lessonId}/ratings`
    const body = { rating }
    return applyForUser ? authPatch(url, body) : patch(url, body)
  }

  /**
   * End new API api names for lesson creation
   * */

  /** Start Lessons Collection **/

  async listLessonSeries() {
    return await authGet(`lessonGroup`)
  }

  async getLessonSeries({ uri }) {
    return await authGet(`lessonGroup/${uri}`)
  }

  async createLessonSeriesBookmark({ uri }) {
    return await authPost(`lessonGroup/${uri}/save`)
  }

  async removeLessonSeriesBookmark({ uri }) {
    return await authPost(`lessonGroup/${uri}/unsave`)
  }

  /** Start Programs **/

  async getProgram({ id }) {
    return await authGet(`programs/${id}`)
  }

  /** Start Sessions **/

  async getSessions({ classId }) {
    return await authGet(`sessions?classId=${classId}`)
  }

  async createSession({ payload }) {
    return await authPost(`sessions`, payload)
  }

  async getSession({ id }) {
    return await authGet(`sessions/${id}`)
  }

  async updateSession(payload) {
    return await authPatch(`sessions/${payload.id}`, payload)
  }

  async deleteSession({ id }) {
    return await authDelete(`sessions/${id}`)
  }

  /** Start Students **/

  async getStudents() {
    return await authGet(`students`)
  }

  async createStudent({ studentData }) {
    return await authPost('students', studentData)
  }

  async updateStudents({ id, studentData }) {
    return await authPatch(`students/${id}`, studentData)
  }

  async deleteStudent({ id }) {
    return await authDelete(`students/${id}`)
  }

  /** Start Users **/

  async userCreate({ provider, credential, ...profile }) {
    return await post('users', {
      provider,
      credential,
      ...profile,
    })
  }

  async userView({ id }) {
    return await authGet(`users/${id}`)
  }

  async userUpdate({ id, ...payload }) {
    return await authPatch(`users/${id}`, payload)
  }

  async userDelete({ id }) {
    return await authDelete(`users/${id}`)
  }

  async importGoogleClassroomCourses({ accountId, state }) {
    return await authGet(`users/${accountId}/imports/google`, state)
  }

  async getSavedLessons({ accountId }) {
    return await authGet(`users/${accountId}/lessons`)
  }

  async updateSavedLessons({ accountId, savePatch }) {
    await authPatch(`users/${accountId}/lessons`, savePatch)
  }

  async getOCCBotGroup({ accountId }) {
    return await authGet(`users/${accountId}/occBotGroup`)
  }

  async setOCCBotGroup({ accountId, occSerial, botIds }) {
    return await authPut(`users/${accountId}/occBotGroup`, {
      occSerial,
      botIds,
    })
  }

  async getProgramsForUser({ accountId }) {
    return await authGet(`users/${accountId}/programs`)
  }

  async updateProgram({ accountId, id, programData }) {
    return await authPatch(`users/${accountId}/programs/${id}`, {
      ...programData,
    })
  }

  async createProgram({ accountId, programData }) {
    return await authPost(`users/${accountId}/programs`, programData)
  }

  async deleteProgram({ accountId, id }) {
    return await authDelete(`users/${accountId}/programs/${id}`)
  }

  async userProgression({ accountId, namespace }) {
    return await authGet(`users/${accountId}/progressions/${namespace}`)
  }

  async updateUserProgression({ accountId, namespace, progress }) {
    return await authPut(
      `users/${accountId}/progressions/${namespace}`,
      progress
    )
  }

  async getStudentLists({ accountId }) {
    return await authGet(`users/${accountId}/studentlists`)
  }

  async createStudentList({ accountId, title }) {
    return await authPost(`users/${accountId}/studentlists`, {
      title: title,
    })
  }

  async updateStudentList({ accountId, listId, listData }) {
    return await authPatch(
      `users/${accountId}/studentlists/${listId}`,
      listData
    )
  }

  async deleteStudentList({ accountId, listId }) {
    return await authDelete(`users/${accountId}/studentlists/${listId}`)
  }

  async listPDCredits({ accountId }) {
    return await authGet(`users/${accountId}/pd-credits`)
  }

  async createPDCredit({
    accountId,
    event,
    eventType,
    title,
    seconds,
    startedAt = undefined,
    endedAt = undefined,
  }) {
    return await authPost(`users/${accountId}/pd-credits`, {
      event,
      eventType,
      title,
      seconds,
      startedAt,
      endedAt,
    })
  }

  async updatePDCredit(payload) {
    return await authPut(
      `users/${payload.accountId}/pd-credits/${payload.id}`,
      {
        ...payload,
      }
    )
  }

  async getStudentView({ accountId }) {
    return authGet(`users/${accountId}/student-view`)
  }

  async userUnlockProduct({ product, accountId, ...props}) {
    const res = await authPost(`users/${accountId}/unlock/${product}`, props)
    // Some unlocks require a refreshed token for new auth capabilities
    await refreshAccessToken(true)
    return res
  }

  async fakeSocketSend({ socketId, to, message }) {
    return await authPost(`fiomessages`, {
      from: socketId,
      to,
      data: message,
    })
  }

  async fakeSocketFetch({ toList, fromTime, toTime }) {
    return await authGet(
      `fiomessages?fromtime=${encodeURIComponent(
        new Date(fromTime).toISOString()
      )}&totime=${encodeURIComponent(
        new Date(toTime).toISOString()
      )}&to=${encodeURIComponent(toList.join(','))}`
    )
  }
}

export default new ClientApi()
